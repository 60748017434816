import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Header from "../components/header";
import Nav from "../components/nav";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  const navigate = useNavigate();

  const passwordHandle = (e) => {
    setPassword(e.target.value);
    setErrorPassword("");
  };

  const confirmPasswordHandle = (e) => {
    setConfirmPassword(e.target.value);
    setErrorConfirmPassword("");
  };

  const confirm = () => {
    var confirm = true;

    if (password.length) {
      if (password.length < 8 && password.length > 20) {
        setErrorPassword("Password must between 8 and 20 character!");
        confirm = false;
      } else {
        setErrorPassword("");
      }
    } else {
      setErrorPassword("New password is required!");
    }

    if (confirmPassword.length) {
      if (confirmPassword === password) {
        setErrorConfirmPassword("");
      } else {
        setErrorConfirmPassword("Password don't match!");
        confirm = false;
      }
    } else {
      setErrorConfirmPassword("Confirm password is required!");
      confirm = false;
    }

    if (confirm) {
      navigate("/");
    }
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav />
      <div className="body-container mt-4 ps-4">
        <div className="profile-container">
          <div className="profile-form-container py-5">
            <p className="form-title">Change Password</p>
            <Form className="login-form">
              <Form.Group className="mb-4">
                <Form.Label className="input-label">New Password</Form.Label>
                <div className="login-input-container">
                  <div className="login-input-box">
                    <input
                      placeholder="New Password"
                      className="login-input"
                      type="password"
                      onChange={(e) => passwordHandle(e)}
                    />
                  </div>
                </div>
                {errorPassword.trim().length ? (
                  <p className="error-input">{errorPassword}</p>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label className={"input-label"}>
                  Confirm Password
                </Form.Label>
                <div className={"login-input-container"}>
                  <div className="login-input-box">
                    <input
                      placeholder="Confirm Password"
                      className="login-input"
                      type="password"
                      onChange={(e) => confirmPasswordHandle(e)}
                    />
                  </div>
                </div>
                {errorConfirmPassword.trim().length ? (
                  <p className="error-input">{errorConfirmPassword}</p>
                ) : null}
              </Form.Group>
            </Form>
            <button
              type="button"
              className="login-btn"
              onClick={() => confirm()}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
