import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";

import Header from "../components/header";
import Nav from "../components/nav";
import Marker from "../assets/image/header/blue_marker.png";
import Building from "../assets/image/dashboard/building.png";
import Floor from "../assets/image/dashboard/floor.png";
import Room from "../assets/image/dashboard/room.png";
import FloorDemo from "../assets/image/dashboard/floor-demo.png";
import Power from "../assets/image/dashboard/power.png";
import PE from "../assets/image/dashboard/pe.png";
import DoubleSkin from "../assets/image/dashboard/double_skin.png";
import FanFilterInverter from "../assets/image/dashboard/fan_filter_inverter.png";
//import { MqttContext } from "../components/mqtt_context";

const MarkerComponent = ({ text }) => (
  <div className="marker-container">
    <div className="marker-title-container">{text}</div>
    <img src={Marker} alt="marker" />
  </div>
);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: "Air Quality",
      padding: {
        bottom: 20,
      },
      font: {
        size: 16,
        family: "SukhumvitSet-Medium",
      },
    },
  },
};

let labels = [];

const Dashboard = (props) => {
  const [building, setBuilding] = useState("อาคาร 1");
  const [floor, setFloor] = useState("ชั้น 1");
  const [room, setRoom] = useState("ห้อง 1");
  const [buildingList, setBuildingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [buildingShow, setBuildingShow] = useState(false);
  const [floorShow, setFloorShow] = useState(false);
  const [roomShow, setRoomShow] = useState(false);
  const [power, setPower] = useState(false);
  const [data, setData] = useState({
    labels,
    datasets: [
      {
        label: "Air Quality",
        data: [],
        borderColor: "#3fbfef",
        backgroundColor: "#66a6ff",
      },
    ],
  });

  const navigate = useNavigate();

  //const test = useContext(MqttContext);

  useEffect(() => {
    setBuildingList(["อาคาร 1", "อาคาร 2", "อาคาร 3"]);
    setFloorList(["ชั้น 1", "ชั้น 2", "ชั้น 3"]);
    setRoomList(["ห้อง 1", "ห้อง 2", "ห้อง 3"]);
  }, []);

  useEffect(() => {
    setBuildingShow(false);
  }, [building]);

  useEffect(() => {
    setFloorShow(false);
  }, [floor]);

  useEffect(() => {
    setRoomShow(false);
  }, [room]);

  useEffect(() => {
    if (building === "อาคาร 1" || room === "ห้อง 2" || floor === "ชั้น 2") {
      labels = [
        "00 am",
        "01 am",
        "02 am",
        "03 am",
        "04 am",
        "05 am",
        "06 am",
        "06 am",
        "07 am",
        "08 am",
        "09 am",
        "10 am",
        "11 am",
        "12 am",
        "01 pm",
        "02 pm",
        "03 pm",
        "04 pm",
        "05 pm",
        "06 pm",
        "07 pm",
        "08 pm",
        "09 pm",
        "10 pm",
        "11 pm",
        "12 pm",
      ];
      setData({
        labels,
        datasets: [
          {
            label: "Air Quality",
            data: [
              12, 23, 8, 20, 22, 30, 12, 23, 8, 20, 22, 30, 12, 23, 8, 20, 22,
              30, 12, 23, 8, 20, 22, 30, 22, 19,
            ],
            borderColor: "#3fbfef",
            backgroundColor: "#66a6ff",
          },
        ],
      });
    } else {
      labels = ["12 am", "3 am", "6 am", "12 pm", "3 pm", "6 pm"];
      setData({
        labels,
        datasets: [
          {
            label: "Air Quality",
            data: [99, 22, 34, 12, 232, 1],
            borderColor: "#3fbfef",
            backgroundColor: "#66a6ff",
          },
        ],
      });
    }
  }, [building, floor, room]);

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container">
        <Row className="mx-0 ps-4">
          <Col xs={12}>
            <h3 className="dashboard-header mb-0">
              {building + " " + floor + " " + room}
            </h3>
          </Col>
          <Col xs={6}>
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDgBvW3DijKFJ63irJPP9CebaUg_yP5lXY",
                }}
                defaultCenter={{ lat: 13.8494444, lng: 100.5310543 }}
                defaultZoom={16}
                disableDoubleClickZoom={true}
                zoomControl={false}
                draggable={false}
              >
                <MarkerComponent
                  lat={13.8494444}
                  lng={100.5310543}
                  text="โรงพยาบาล xxxxxxxxxxxxxxxxxxxxxx"
                />
              </GoogleMapReact>
            </div>
          </Col>
          <Col xs={6} className="dashboard-right-container pe-0">
            <div className="dashboard-card pb-2">
              <div className="dashboard-card-header">
                <div className="dashboard-card-icon-container">
                  <img src={Building} alt="building" className="mx-2" />
                  {building}
                </div>
                <div
                  className="card-header-chevron"
                  onClick={() => setBuildingShow(!buildingShow)}
                ></div>
                <div
                  className="card-dropdown"
                  style={{
                    visibility: buildingShow ? "visible" : "hidden",
                    opacity: buildingShow ? 1 : 0,
                  }}
                >
                  {buildingList.map((item, key) => (
                    <div
                      className="card-dropdown-btn"
                      onClick={() => setBuilding(item)}
                      key={key}
                    >
                      <p className="w-100 mb-0">{item}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="dashboard-card-body"
                style={{ background: "url(" + FloorDemo + ")" }}
              ></div>
            </div>
            <div className="dashboard-card pt-2">
              <div className="dashboard-card-header">
                <div className="dashboard-card-icon-container">
                  <img src={Floor} alt="floor" className="mx-2" />
                  {floor}
                </div>
                <div
                  className="card-header-chevron"
                  onClick={() => setFloorShow(!floorShow)}
                ></div>
                <div
                  className="card-dropdown"
                  style={{
                    visibility: floorShow ? "visible" : "hidden",
                    opacity: floorShow ? 1 : 0,
                  }}
                >
                  {floorList.map((item, key) => (
                    <div
                      className="card-dropdown-btn"
                      key={key}
                      onClick={() => setFloor(item)}
                    >
                      <p className="w-100 mb-0">{item}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="dashboard-card-body"
                style={{ background: "url(" + FloorDemo + ")" }}
              ></div>
            </div>
          </Col>
          <Col xs={12} className="pe-0 pt-4">
            <div className="dashboard-card">
              <div className="dashboard-card-header">
                <div className="dashboard-card-icon-container">
                  <img src={Room} alt="room" className="mx-2" />
                  {room}
                </div>
                <div
                  className="card-header-chevron"
                  onClick={() => setRoomShow(!roomShow)}
                ></div>
                <div
                  className="card-dropdown"
                  style={{
                    visibility: roomShow ? "visible" : "hidden",
                    opacity: roomShow ? 1 : 0,
                  }}
                >
                  {roomList.map((item, key) => (
                    <div
                      className="card-dropdown-btn"
                      key={key}
                      onClick={() => setRoom(item)}
                    >
                      <p className="w-100 mb-0">{item}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="dashboard-card-body">
                <div className="graph-container">
                  <Line options={options} data={data} />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={2} className="my-5 pe-0">
            <div className="dashboard-progress-card">
              Temperature
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={25}
                  maxValue={50}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">25</span>
                    <span className="dashboard-unit">°C</span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col xs={2} className="my-5 pe-0">
            <div className="dashboard-progress-card">
              Air Change
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={12}
                  maxValue={20}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">12</span>
                    <span className="dashboard-unit">ACH</span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col xs={2} className="my-5 pe-0">
            <div className="dashboard-progress-card">
              Carbon Dioxide
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={400}
                  maxValue={700}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">400</span>
                    <span className="dashboard-unit">ppm</span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col xs={2} className="my-5 pe-0">
            <div className="dashboard-progress-card">
              PM 2.5
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={50}
                  maxValue={500}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">50</span>
                    <span className="dashboard-unit">
                      ug/m<sup>3</sup>
                    </span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col xs={2} className="my-5 pe-0">
            <div className="dashboard-progress-card">
              Humidity
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={70}
                  maxValue={100}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">70</span>
                    <span className="dashboard-unit">%rh</span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col xs={2} className="my-5 pe-0">
            <div className="dashboard-progress-card">
              Dust Mite
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={20}
                  maxValue={50}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">20</span>
                    <span className="dashboard-unit">Hrs</span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mx-0 ps-4">
          <Col className="pe-0">
            <Row className="dashboard-product-container mx-0 mb-2">
              <Col xs={2} className="dashboard-image-container">
                <img src={PE} alt="pe" />
              </Col>
              <Col xs={4} className="dashboard-product-name-container px-0">
                <div className="dashboard-name-label px-4 py-1 mt-4">
                  Name 01
                </div>
                <p className="dashboard-model px-4 mt-1 mb-0">
                  Protective Environment
                </p>
                <p className="dashboard-serial px-4">Item000000000</p>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="dashboard-other-label-container">
                  <div className="dashboard-product-status" />
                  <span className="dashboard-other-label">Status</span>
                </div>
                <div className="dashboard-status-container">Good</div>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="dashboard-other-label-container">
                  <img
                    src={Power}
                    className="dashboard-other-icon"
                    alt="power"
                  />
                  <span className="dashboard-other-label">Power</span>
                </div>
                <div className="dashboard-switch-container">
                  <span className="me-2">OFF</span>
                  <Switch
                    onChange={() => setPower(!power)}
                    checked={power}
                    onColor="#70a5f9"
                    onHandleColor="#1d2860"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 8px rgba(0, 0, 0, 0.2)"
                  />
                  <span className="ms-2">ON</span>
                </div>
              </Col>
              <Col className="dashboard-other-container">
                <div className="dashboard-other-btn-container">
                  <button
                    type="button"
                    className="dashboard-control-btn mt-3"
                    onClick={() =>
                      navigate("/control", {
                        state: {
                          header: "อาคาร 1 ชั้น 1 ห้อง 1",
                        },
                      })
                    }
                  >
                    Control
                  </button>
                  <button
                    type="button"
                    className="dashboard-notify-btn disabled mb-3"
                    disabled
                    onClick={() => navigate("/notify")}
                  >
                    Notify
                  </button>
                </div>
              </Col>
            </Row>
            <Row className="dashboard-product-container mx-0 mb-2">
              <Col xs={2} className="dashboard-image-container">
                <img src={DoubleSkin} alt="DoubleSkin" />
              </Col>
              <Col xs={4} className="dashboard-product-name-container px-0">
                <div className="dashboard-name-label px-4 py-1 mt-4">
                  Name 02
                </div>
                <p className="dashboard-model px-4 mt-1 mb-0">Double Skin</p>
                <p className="dashboard-serial px-4">Item000000000</p>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="dashboard-other-label-container">
                  <div className="dashboard-product-status service-status" />
                  <span className="dashboard-other-label">Status</span>
                </div>
                <div className="dashboard-status-container service-status">
                  Service
                </div>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="dashboard-other-label-container">
                  <img
                    src={Power}
                    className="dashboard-other-icon"
                    alt="power"
                  />
                  <span className="dashboard-other-label">Power</span>
                </div>
                <div className="dashboard-switch-container">
                  <span className="me-2">OFF</span>
                  <Switch
                    onChange={() => setPower(!power)}
                    checked={power}
                    onColor="#70a5f9"
                    onHandleColor="#1d2860"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 8px rgba(0, 0, 0, 0.2)"
                  />
                  <span className="ms-2">ON</span>
                </div>
              </Col>
              <Col className="dashboard-other-container">
                <div className="dashboard-other-btn-container">
                  <button
                    type="button"
                    className="dashboard-control-btn mt-3"
                    onClick={() => navigate("/control")}
                  >
                    Control
                  </button>
                  <button
                    type="button"
                    className="dashboard-notify-btn disabled mb-3"
                    onClick={() => navigate("/notify")}
                  >
                    Notify
                  </button>
                </div>
              </Col>
            </Row>
            <Row className="dashboard-product-container mx-0 mb-2">
              <Col xs={2} className="dashboard-image-container">
                <img src={FanFilterInverter} alt="FanFilterInverter" />
              </Col>
              <Col xs={4} className="dashboard-product-name-container px-0">
                <div className="dashboard-name-label px-4 py-1 mt-4">
                  Name 03
                </div>
                <p className="dashboard-model px-4 mt-1 mb-0">
                  Fan Filter Inverter
                </p>
                <p className="dashboard-serial px-4">Item000000000</p>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="dashboard-other-label-container">
                  <div className="dashboard-product-status" />
                  <span className="dashboard-other-label">Status</span>
                </div>
                <div className="dashboard-status-container">Good</div>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="dashboard-other-label-container">
                  <img
                    src={Power}
                    className="dashboard-other-icon"
                    alt="power"
                  />
                  <span className="dashboard-other-label">Power</span>
                </div>
                <div className="dashboard-switch-container">
                  <span className="me-2">OFF</span>
                  <Switch
                    onChange={() => setPower(!power)}
                    checked={power}
                    onColor="#70a5f9"
                    onHandleColor="#1d2860"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 8px rgba(0, 0, 0, 0.2)"
                  />
                  <span className="ms-2">ON</span>
                </div>
              </Col>
              <Col className="dashboard-other-container">
                <div className="dashboard-other-btn-container">
                  <button
                    type="button"
                    className="dashboard-control-btn mt-3"
                    onClick={() => navigate("/control")}
                  >
                    Control
                  </button>
                  <button
                    type="button"
                    className="dashboard-notify-btn disabled mb-3"
                    disabled
                    onClick={() => navigate("/notify")}
                  >
                    Notify
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
