import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Draggable from "react-draggable";

import Header from "../components/header";
import Nav from "../components/nav";

import DemoRoom from "../assets/image/control/demo-room.png";
import Type_1 from "../assets/image/control/type-1.png";
import Type_2 from "../assets/image/control/type-2.png";
import Type_3 from "../assets/image/control/type-3.png";
import Type_4 from "../assets/image/control/type-4.png";

const SettingPosition = (props) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [activeList, setActiveList] = useState([]);
  const [inactiveList, setInactiveList] = useState([]);

  useEffect(() => {
    let ac_list = [
      {
        acName: "Name 01",
        acType: "Protective Environment",
        acItem: "Item000000000",
        axisX: 45,
        axisY: 45,
        active: true,
      },
      {
        acName: "Name 02",
        acType: "Double Skin",
        acItem: "Item000000000",
        axisX: 70,
        axisY: 70,
        active: true,
      },
      {
        acName: "Name 03",
        acType: "Fan Filter Inverter",
        acItem: "Item000000000",
        axisX: 0,
        axisY: 0,
        active: false,
      },
    ];

    let active_list = ac_list.filter((data) => data.active === true);
    let inactive_list = ac_list.filter((data) => data.active === false);

    setActiveList(active_list);
    setInactiveList(inactive_list);
  }, []);

  const handleDrag = (e, ui, item) => {
    let active_list = activeList.map((data) => {
      if (item.acName === data.acName) {
        data.axisX = (((item.axisX / 100) * width + ui.deltaX) / width) * 100;
        data.axisY = (((item.axisY / 100) * height + ui.deltaY) / height) * 100;
      }

      return data;
    });

    setActiveList(active_list);
  };

  const onImgLoad = ({ target: img }) => {
    setHeight(img.offsetHeight);
    setWidth(img.offsetWidth);
  };

  const acImage = (type) => {
    switch (true) {
      case type === "Protective Environment":
        return Type_1;
      case type === "Double Skin":
        return Type_2;
      case type === "Fan Filter Inverter":
        return Type_3;
      default:
        return Type_4;
    }
  };

  const active = (acData) => {
    let inactive_list = inactiveList.filter((a) => a !== acData);

    acData.active = true;

    activeList.push(acData);

    setActiveList(activeList);
    setInactiveList(inactive_list);
  };

  const remove = (acData) => {
    let active_list = activeList.filter((a) => a !== acData);

    acData.active = false;
    acData.axisX = 0;
    acData.axisY = 0;

    inactiveList.push(acData);

    setActiveList(active_list);
    setInactiveList(inactiveList);
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4">
        <Row className="mx-0 ps-4">
          <Col xs={9}>
            <div
              className="table-container-title px-2 mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <h5 className="setting-title mb-0">AC Position Management</h5>
              <button className="save-position-btn">Save</button>
            </div>
          </Col>
          <Col xs={3}>
            <div
              className="table-container-title px-2 mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <h5 className="setting-title mb-0">AC List</h5>
            </div>
          </Col>
          <Col xs={9}>
            <div className="control-plan-container" style={{ height: "80vh" }}>
              <div className="control-plan">
                <img
                  src={DemoRoom}
                  alt="room"
                  className="room-plan"
                  onLoad={onImgLoad}
                />
                <div className="room-img-front">
                  {activeList.map((item, index) => (
                    <Draggable
                      axis="both"
                      handle={".drag-ac"}
                      position={{
                        x: (item.axisX / 100) * width,
                        y: (item.axisY / 100) * height,
                      }}
                      scale={1}
                      onDrag={(e, ui) => handleDrag(e, ui, item)}
                      bounds="parent"
                      key={index}
                    >
                      <div className="ac-container">
                        <img src={acImage(item.acType)} alt="AC" />
                        <div className="drag-ac"></div>
                        <div
                          className={
                            (item.axisY / height) * 100 > 45
                              ? "setting-ac-info-top"
                              : "setting-ac-info-bottom"
                          }
                        >
                          <p className="ac-name mb-1">{item.acName}</p>
                          <p className="ac-title">
                            AC Type:{" "}
                            <span className="ac-status">{item.acType}</span>
                          </p>
                          <p className="ac-title">
                            AC Item.:{" "}
                            <span className="ac-status">{item.acItem}</span>
                          </p>
                        </div>
                        <div className="delete-ac" onClick={() => remove(item)}>
                          x
                        </div>
                      </div>
                    </Draggable>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="ac-list-card-container">
              {inactiveList.map((item, index) => (
                <div className="ac-list-card" key={index}>
                  <p className="ac-title mb-1">{item.acName}</p>
                  <Row className="mx-0">
                    <Col xs={9} className="px-0">
                      <p className="ac-description mb-0">{item.acType}</p>
                      <p className="ac-description mb-0">{item.acItem}</p>
                    </Col>
                    <Col className="add-ac-btn-container px-0">
                      <button
                        className="add-ac-btn"
                        onClick={() => active(item)}
                      >
                        +
                      </button>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SettingPosition;
