import React from "react";
import { Row, Col } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

import Header from "../components/header";
import Nav from "../components/nav";

import PE from "../assets/image/dashboard/pe.png";
import DoubleSkin from "../assets/image/dashboard/double_skin.png";
import FanFilterInverter from "../assets/image/dashboard/fan_filter_inverter.png";

const Product = (props) => {
  // const navigate = useNavigate();

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-5 pt-4">
        <Row className="mx-0 ps-4">
          <Col className="pe-0">
            <Row className="product-container mx-0 mb-2">
              <Col xs={2} className="dashboard-image-container">
                <img src={PE} alt="pe" />
              </Col>
              <Col xs={4} className="dashboard-product-name-container px-0">
                <div className="dashboard-name-label px-4 py-1 mt-4">
                  Name 01
                </div>
                <p className="dashboard-model px-4 mt-1 mb-0">
                  Protective Environment
                </p>
                <p className="dashboard-serial px-4">Item000000000</p>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="product-date-container">
                  <span className="product-date-label">วันที่ติดตั้ง</span>
                  <span className="product-date">DD / MM / YY</span>
                </div>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="product-date-container">
                  <span className="product-date-label">
                    วันหมดอายุรับประกัน
                  </span>
                  <span className="product-date">DD / MM / YY</span>
                </div>
              </Col>
              <Col className="dashboard-other-container">
                <div className="warranty-container">
                  <div className="warranty-add-btn">+</div>
                </div>
              </Col>
            </Row>
            <Row className="product-container mx-0 mb-2">
              <Col xs={2} className="dashboard-image-container">
                <img src={DoubleSkin} alt="DoubleSkin" />
              </Col>
              <Col xs={4} className="dashboard-product-name-container px-0">
                <div className="dashboard-name-label px-4 py-1 mt-4">
                  Name 02
                </div>
                <p className="dashboard-model px-4 mt-1 mb-0">Double Skin</p>
                <p className="dashboard-serial px-4">Item000000000</p>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="product-date-container">
                  <span className="product-date-label">วันที่ติดตั้ง</span>
                  <span className="product-date">DD / MM / YY</span>
                </div>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="product-date-container">
                  <span className="product-date-label">
                    วันหมดอายุรับประกัน
                  </span>
                  <span className="product-date">DD / MM / YY</span>
                </div>
              </Col>
              <Col className="dashboard-other-container">
                <div className="warranty-container">
                  <div className="warranty-add-btn">+</div>
                </div>
              </Col>
            </Row>
            <Row className="product-container mx-0 mb-2">
              <Col xs={2} className="dashboard-image-container">
                <img src={FanFilterInverter} alt="FanFilterInverter" />
              </Col>
              <Col xs={4} className="dashboard-product-name-container px-0">
                <div className="dashboard-name-label px-4 py-1 mt-4">
                  Name 03
                </div>
                <p className="dashboard-model px-4 mt-1 mb-0">
                  Fan Filter Inverter
                </p>
                <p className="dashboard-serial px-4">Item000000000</p>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="product-date-container">
                  <span className="product-date-label">วันที่ติดตั้ง</span>
                  <span className="product-date">DD / MM / YY</span>
                </div>
              </Col>
              <Col xs={2} className="dashboard-other-container">
                <div className="product-date-container">
                  <span className="product-date-label">
                    วันหมดอายุรับประกัน
                  </span>
                  <span className="product-date">DD / MM / YY</span>
                </div>
              </Col>
              <Col className="dashboard-other-container">
                <div className="warranty-container">
                  <div className="warranty-add-btn">+</div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Product;
