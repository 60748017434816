import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import Header from "../components/header";
import Nav from "../components/nav";

import Temp from "../assets/image/control/temp.png";
import Humidity from "../assets/image/control/humidity.png";
import Cool from "../assets/image/control/cool.png";
import Fan from "../assets/image/control/fan.png";
import Dry from "../assets/image/control/dry.png";
import Heat from "../assets/image/control/heat.png";
import Auto from "../assets/image/control/auto.png";
import Manual from "../assets/image/control/manual.png";
import AutoProtective from "../assets/image/control/auto_protective.png";
import FanLow from "../assets/image/control/fan_low.png";
import FanMid from "../assets/image/control/fan_mid.png";
import FanHigh from "../assets/image/control/fan_high.png";
import FanHiHi from "../assets/image/control/fan_hi_hi.png";
import FanTurbo from "../assets/image/control/fan_turbo.png";
import FanAuto from "../assets/image/control/fan_auto.png";
import Swing_1 from "../assets/image/control/swing_1.png";
import Swing_2 from "../assets/image/control/swing_2.png";
import Swing_3 from "../assets/image/control/swing_3.png";
import Swing_4 from "../assets/image/control/swing_4.png";
import Swing_5 from "../assets/image/control/swing_5.png";
import SwingAuto from "../assets/image/control/swing_auto.png";
import AutoFunction from "../assets/image/control/function_auto.png";
import AutoProtection from "../assets/image/control/function_protection.png";
import OzoneFilter from "../assets/image/control/function_ozone.png";
import Power from "../assets/image/control/power.png";
import Plan from "../assets/image/control/plan.png";
import Icon from "../assets/image/control/icon.png";
import Info from "../assets/image/control/info.png";
import DemoRoom from "../assets/image/control/demo-room.png";
import Type_1 from "../assets/image/control/type-1.gif";
import Type_2 from "../assets/image/control/type-2.gif";
import Type_3 from "../assets/image/control/type-3.gif";
import Type_4 from "../assets/image/control/type-4.gif";
import BuildingIcon from "../assets/image/control/building-icon.png";
import FloorIcon from "../assets/image/control/floor-icon.png";
import RoomIcon from "../assets/image/control/room-icon.png";

const Control = (props) => {
  const { state } = useLocation();

  const [header, setHeader] = useState(
    state?.header ? state.header : "อาคาร 1 ชั้น 1 ห้อง 1"
  );
  const [temp, setTemp] = useState(15);
  const [humidity, setHumidity] = useState(50);
  const [roomTemp, setRoomTemp] = useState(25.5);
  const [roomHumidity, setRoomHumidity] = useState(50);
  const [mode, setMode] = useState("cool");
  const [peMode, setPeMode] = useState("manual");
  const [fan, setFan] = useState("low");
  const [swing, setSwing] = useState("h");
  const [acFunction, setAcFunction] = useState("auto-function");
  const [power, setPower] = useState(true);
  const [viewMode, setViewMode] = useState("plan");
  const [showIcon, setShowIcon] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const [building, setBuilding] = useState("1");
  const [floor, setFloor] = useState("1");
  const [room, setRoom] = useState("1");
  //const [acList, setAcList] = useState([]);

  const tempHandle = (val) => {
    if (val === "inc") {
      setTemp(temp >= 30 ? 30 : temp + 0.5);
    } else {
      setTemp(temp <= 15 ? 15 : temp - 0.5);
    }
  };

  const humidityHandle = (val) => {
    if (val === "inc") {
      setHumidity(humidity >= 100 ? 100 : humidity + 1);
    } else {
      setHumidity(humidity <= 0 ? 0 : humidity - 1);
    }
  };

  const viewModeHandle = (mode) => {
    setViewMode(mode);

    switch (true) {
      case mode === "icon":
        setShowIcon(true);
        setShowInfo(false);
        break;
      case mode === "info":
        setShowIcon(true);
        setShowInfo(true);
        break;
      default:
        setShowIcon(false);
        setShowInfo(false);
        break;
    }
  };

  const filterACRoom = () => {
    setOpen(false);

    setHeader("อาคาร " + building + " ชั้น " + floor + " ห้อง " + room);

    if (room === "2") {
      setRoomTemp(30);
    } else {
      setRoomTemp(25.5);
    }
  };

  useEffect(() => {
    setBuilding("1");
    setFloor("1");
    setRoom("1");
  }, []);

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container">
        <Row className="mx-0 ps-4">
          <Col xs={"auto"} className="position-relative">
            <h3 className="dashboard-header mb-0">{header}</h3>
            <div
              className="control-select-dropdown"
              onClick={() => setOpen(!open)}
            ></div>
            <div
              className="control-select-container p-2"
              style={{
                visibility: open ? "visible" : "hidden",
                opacity: open ? 1 : 0,
              }}
            >
              <div className="control-select">
                <img src={BuildingIcon} alt="building" className="pe-2" />
                <p className="mb-0 pe-2">Building</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => setBuilding(e.target.value)}
                >
                  <option value="1">Building 1</option>
                  <option value="2">Building 2</option>
                  <option value="3">Building 3</option>
                </Form.Select>
              </div>
              <div className="control-select">
                <img src={FloorIcon} alt="floor" className="pe-2" />
                <p className="mb-0 pe-2">Floor</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => setFloor(e.target.value)}
                >
                  <option value="1">Floor 1</option>
                  <option value="2">Floor 2</option>
                  <option value="3">Floor 3</option>
                </Form.Select>
              </div>
              <div className="control-select">
                <img src={RoomIcon} alt="floor" className="pe-2" />
                <p className="mb-0 pe-2">Room</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => setRoom(e.target.value)}
                >
                  <option value="1">Room 1</option>
                  <option value="2">Room 2</option>
                  <option value="3">Room 3</option>
                </Form.Select>
              </div>
              <button
                className="control-search-btn my-2 me-2"
                onClick={() => filterACRoom()}
              >
                Confirm
              </button>
            </div>
          </Col>
        </Row>
        <Row className="mx-0 ps-4 control-container">
          <Col xs={3} className="control-left-colum pe-0">
            <div
              className={
                roomTemp >= 30
                  ? "control-room-temp-container hot mb-2"
                  : "control-room-temp-container cool mb-2"
              }
            >
              <p className="room-temp-label mb-2">Room Temp</p>
              <div className="monitor-container">
                <div className="monitor-value">
                  <img src={Temp} alt="temp" />
                  <p className="mb-0">
                    {roomTemp}
                    <span> °C</span>
                  </p>
                </div>
                <div className="monitor-value">
                  <img src={Humidity} alt="humidity" className="pe-2" />
                  <p className="mb-0">
                    50<span> %</span>
                  </p>
                </div>
              </div>
            </div>
            <Row className="mx-0 h-100 mb-2">
              <Col className="ps-0 pe-1">
                <div className="control-room-control-container mb-2">
                  <div className="control-container-panel">
                    <p className="control-label mb-0">SET TEMP</p>
                    <div className="temp-panel">
                      <div
                        className="arrow-up mb-2"
                        onClick={() => tempHandle("inc")}
                      ></div>
                      {temp}
                      <br />
                      <span>°C</span>
                      <div
                        className="arrow-down mt-2"
                        onClick={() => tempHandle("dec")}
                      ></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="pe-0 ps-1">
                <div className="control-room-control-container mb-2">
                  <div className="control-container-panel">
                    <p className="control-label mb-0">HUMIDITY</p>
                    <div className="temp-panel">
                      <div
                        className="arrow-up mb-2"
                        onClick={() => humidityHandle("inc")}
                      ></div>
                      {humidity}
                      <br />
                      <span>%</span>
                      <div
                        className="arrow-down mt-2"
                        onClick={() => humidityHandle("dec")}
                      ></div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={5} className="control-center-colum pe-0 ps-2">
            <div className="control-room-control-container mb-2">
              <p className="control-label mb-2">MODE</p>
              <Row className="mx-0">
                <Col xs={8} className="pe-1">
                  <div className="control-mode-container">
                    <button
                      className={
                        mode === "cool"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setMode("cool")}
                      disabled={false}
                    >
                      <img src={Cool} alt="cool" className="mb-2" />
                      Cool
                    </button>
                    <button
                      className={
                        mode === "fan"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      disabled={false}
                      onClick={() => setMode("fan")}
                    >
                      <img src={Fan} alt="fan" className="mb-2" />
                      Fan Only
                    </button>
                    <button
                      className={
                        mode === "dry"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setMode("dry")}
                      disabled={false}
                    >
                      <img src={Dry} alt="dry" className="mb-2" />
                      Dry
                    </button>
                    <button
                      className={
                        mode === "heat"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setMode("heat")}
                      disabled={false}
                    >
                      <img src={Heat} alt="heat" className="mb-2" />
                      Heat
                    </button>
                    <button
                      className={
                        mode === "auto"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setMode("auto")}
                      disabled={false}
                    >
                      <img src={Auto} alt="auto" className="mb-2" />
                      &nbsp;
                    </button>
                    <div className="control-right-border" />
                  </div>
                </Col>
                <Col className="ps-1">
                  <div className="control-mode-container">
                    <button
                      className={
                        peMode === "manual"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setPeMode("manual")}
                      disabled={true}
                    >
                      <img src={Manual} alt="manual" className="mb-2" />
                      Manual
                    </button>
                    <button
                      className={
                        peMode === "auto"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setPeMode("auto")}
                      disabled={true}
                    >
                      <img
                        src={AutoProtective}
                        alt="auto-protective"
                        className="mb-2"
                      />
                      Auto Protective
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="control-room-control-container mb-2">
              <p className="control-label mb-2 disabled">FAN SPEED</p>
              <Row className="mx-0">
                <Col>
                  <div className="control-mode-container">
                    <button
                      className={
                        fan === "low"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setFan("low")}
                      disabled={true}
                    >
                      <img src={FanLow} alt="fan-low" />
                    </button>
                    <button
                      className={
                        fan === "mid"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setFan("mid")}
                      disabled={true}
                    >
                      <img src={FanMid} alt="fan-mid" />
                    </button>
                    <button
                      className={
                        fan === "high"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setFan("high")}
                      disabled={true}
                    >
                      <img src={FanHigh} alt="fan-high" />
                    </button>
                    <button
                      className={
                        fan === "hi-hi"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setFan("hi-hi")}
                      disabled={true}
                    >
                      <img src={FanHiHi} alt="fan-hi-hi" />
                    </button>
                    <button
                      className={
                        fan === "turbo"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setFan("turbo")}
                      disabled={true}
                    >
                      <img src={FanTurbo} alt="fan-turbo" />
                    </button>
                    <button
                      className={
                        fan === "auto"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setFan("auto")}
                      disabled={true}
                    >
                      <img src={FanAuto} alt="fan-auto" />
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="control-room-control-container mb-2">
              <p className="control-label mb-2">SWING</p>
              <Row className="mx-0 pt-2">
                <Col>
                  <div className="control-mode-container">
                    <button
                      className={
                        swing === "h"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setSwing("h")}
                      disabled={false}
                    >
                      <img src={Swing_1} alt="fan-low" />
                    </button>
                    <button
                      className={
                        swing === "3"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setSwing("3")}
                      disabled={false}
                    >
                      <img src={Swing_2} alt="fan-mid" />
                    </button>
                    <button
                      className={
                        swing === "4"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setSwing("4")}
                      disabled={false}
                    >
                      <img src={Swing_3} alt="fan-high" />
                    </button>
                    <button
                      className={
                        swing === "6"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setSwing("6")}
                      disabled={false}
                    >
                      <img src={Swing_4} alt="fan-hi-hi" />
                    </button>
                    <button
                      className={
                        swing === "v"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setSwing("v")}
                      disabled={false}
                    >
                      <img src={Swing_5} alt="fan-turbo" />
                    </button>
                    <button
                      className={
                        swing === "auto"
                          ? "mode-container active"
                          : "mode-container"
                      }
                      onClick={() => setSwing("auto")}
                      disabled={false}
                    >
                      <img src={SwingAuto} alt="fan-auto" />
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="control-right-colum pe-0 ps-2">
            <div className="control-room-control-container mb-2">
              <p className="control-label my-2 text-center mx-0">FUNCTION</p>
              <div className="control-vertical-container">
                <button
                  className={
                    acFunction === "auto-function"
                      ? "mode-container active"
                      : "mode-container"
                  }
                  onClick={() => setAcFunction("auto-function")}
                  disabled={false}
                >
                  <img
                    src={AutoFunction}
                    alt="auto-function"
                    className="mb-2"
                  />
                  Auto Function
                </button>
                <button
                  className={
                    acFunction === "auto-protective"
                      ? "mode-container active"
                      : "mode-container"
                  }
                  onClick={() => setAcFunction("auto-protective")}
                  disabled={false}
                >
                  <img
                    src={AutoProtection}
                    alt="auto-protective"
                    className="mb-2"
                  />
                  Auto Protection 24 hrs
                </button>
                <button
                  className={
                    acFunction === "ozone-filter"
                      ? "mode-container active"
                      : "mode-container"
                  }
                  onClick={() => setAcFunction("ozone-filter")}
                  disabled={false}
                >
                  <img src={OzoneFilter} alt="ozone-filter" className="mb-2" />
                  Ozone Filter Disinfection
                </button>
              </div>
            </div>
          </Col>
          <Col className="control-right-colum pe-0 ps-2">
            <div className="control-room-control-container mb-2">
              <p className="control-label my-2 text-center mx-0">POWER</p>
              <div className="control-vertical-container">
                <button
                  className={power ? "mode-container active" : "mode-container"}
                  onClick={() => setPower(!power)}
                  disabled={false}
                >
                  <img src={Power} alt="power" className="mb-3" />
                  <span className="power-status">{power ? "ON" : "OFF"}</span>
                </button>
              </div>
            </div>
          </Col>
          <Col className="control-right-colum pe-0 ps-2">
            <div className="plan-view-container mb-2">
              <div
                className={
                  viewMode === "plan"
                    ? "plan-view-btn-container active"
                    : "plan-view-btn-container"
                }
                onClick={() => viewModeHandle("plan")}
              >
                <div className="plan-view-btn mb-1">
                  <img src={Plan} alt="plan" />
                </div>
                Plan
              </div>
              <div
                className={
                  viewMode === "icon"
                    ? "plan-view-btn-container active"
                    : "plan-view-btn-container"
                }
                onClick={() => viewModeHandle("icon")}
              >
                <div className="plan-view-btn mb-1">
                  <img src={Icon} alt="icon" />
                </div>
                Icon
              </div>
              <div
                className={
                  viewMode === "info"
                    ? "plan-view-btn-container active"
                    : "plan-view-btn-container"
                }
                onClick={() => viewModeHandle("info")}
              >
                <div className="plan-view-btn mb-1">
                  <img src={Info} alt="info" />
                </div>
                Info
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mx-0 ps-4 mt-4">
          <Col>
            <div className="control-plan-container">
              <div className="control-plan">
                <img src={DemoRoom} alt="room" className="room-plan" />
                <div
                  className="ac-container"
                  style={{
                    top: "45%",
                    left: "5%",
                    visibility: showIcon ? "visible" : "hidden",
                  }}
                >
                  <img src={Type_1} alt="AC" />
                  <div
                    className="ac-info-top"
                    style={{ visibility: showInfo ? "visible" : "hidden" }}
                  >
                    <p className="ac-name mb-1">Name 01</p>
                    <p className="ac-title">
                      Status: <span className="ac-status power-on">ON</span>
                    </p>
                    <p className="ac-title">
                      Set Temp.: <span className="ac-status">17°C</span>
                    </p>
                    <p className="ac-title">
                      Current Temp.: <span className="ac-status">29°C</span>
                    </p>
                  </div>
                </div>
                <div
                  className="ac-container"
                  style={{
                    top: "48%",
                    left: "26%",
                    visibility: showIcon ? "visible" : "hidden",
                  }}
                >
                  <img src={Type_2} alt="AC" />
                  <div
                    className="ac-info-top"
                    style={{ visibility: showInfo ? "visible" : "hidden" }}
                  >
                    <p className="ac-name mb-1">Name 02</p>
                    <p className="ac-title">
                      Status: <span className="ac-status power-on">ON</span>
                    </p>
                    <p className="ac-title">
                      Set Temp.: <span className="ac-status">17°C</span>
                    </p>
                    <p className="ac-title">
                      Current Temp.: <span className="ac-status">29°C</span>
                    </p>
                  </div>
                </div>
                <div
                  className="ac-container"
                  style={{
                    top: "48%",
                    left: "52%",
                    visibility: showIcon ? "visible" : "hidden",
                  }}
                >
                  <img src={Type_3} alt="AC" />
                  <div
                    className="ac-info-top"
                    style={{ visibility: showInfo ? "visible" : "hidden" }}
                  >
                    <p className="ac-name mb-1">Name 03</p>
                    <p className="ac-title">
                      Status: <span className="ac-status power-on">ON</span>
                    </p>
                    <p className="ac-title">
                      Set Temp.: <span className="ac-status">17°C</span>
                    </p>
                    <p className="ac-title">
                      Current Temp.: <span className="ac-status">29°C</span>
                    </p>
                  </div>
                </div>
                <div
                  className="ac-container"
                  style={{
                    top: "25%",
                    left: "77.5%",
                    visibility: showIcon ? "visible" : "hidden",
                  }}
                >
                  <img src={Type_4} alt="AC" />
                  <div
                    className="ac-info-bottom"
                    style={{ visibility: showInfo ? "visible" : "hidden" }}
                  >
                    <p className="ac-name mb-1">Name 04</p>
                    <p className="ac-title">
                      Status: <span className="ac-status power-off">OFF</span>
                    </p>
                    <p className="ac-title">
                      Set Temp.: <span className="ac-status">17°C</span>
                    </p>
                    <p className="ac-title">
                      Current Temp.: <span className="ac-status">29°C</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Control;
