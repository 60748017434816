import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const emailHandle = (e) => {
    setEmail(e.target.value);
    setErrorEmail("");
  };

  const confirm = () => {
    if (email.length) {
      if (validateEmail(email)) {
        setErrorEmail("");
        setModalShow(true);
      } else {
        setErrorEmail("Email is not valid!");
      }
    } else {
      setErrorEmail("Email is required!");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="login-bg">
      <div className="login-container py-5">
        <p className="form-title">Forget Password</p>
        <Form className="login-form">
          <Form.Group className="mb-4">
            <Form.Label className={"input-label"}>Email</Form.Label>
            <div className={"login-input-container"}>
              <div className="login-input-box">
                <input
                  placeholder="Email"
                  className="login-input"
                  onChange={(e) => emailHandle(e)}
                />
              </div>
            </div>
            {errorEmail.trim().length ? (
              <p className="error-input">{errorEmail}</p>
            ) : null}
          </Form.Group>
        </Form>
        <button type="button" className="login-btn" onClick={() => confirm()}>
          Confirm
        </button>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className="modal-title mb-2">Success!</h4>
          <p className="modal-des">
            Password rest request was sent successfully. Please check your email
            to reset your password.
          </p>
          <button
            onClick={() => {
              setModalShow(false);
              navigate("/");
            }}
            className="modal-btn float-end"
          >
            Close
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
