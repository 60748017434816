import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import navLogo from "../assets/image/nav/nav_logo.png";
import dashboardIcon from "../assets/image/nav/dashboard.png";
import controlIcon from "../assets/image/nav/control.png";
import productIcon from "../assets/image/nav/product.png";
import errorIcon from "../assets/image/nav/error.png";
import reportIcon from "../assets/image/nav/report.png";
import settingIcon from "../assets/image/nav/setting.png";

const Nav = (props) => {
  let navigate = useNavigate();

  const [dashboard] = useState(props.page === "dashboard" ? true : false);
  const [control, setControl] = useState(
    props.page === "control" ? true : false
  );
  const [controlOpen, setControlOpen] = useState(false);
  const [product] = useState(props.page === "product" ? true : false);
  const [error] = useState(props.page === "error" ? true : false);
  const [report, setReport] = useState(props.page === "report" ? true : false);
  const [setting, setSetting] = useState(
    props.page === "setting" ? true : false
  );
  const [reportOpen, setReportOpen] = useState(false);
  const [settingOpen, setSettingOpen] = useState(false);

  return (
    <div className="nav-container">
      <img
        src={navLogo}
        className="nav-logo"
        alt="saijo-denki"
        onClick={() => navigate("/")}
      />
      <div className="nav-btn-container">
        <div
          className={dashboard ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => navigate("/")}
        >
          <img
            src={dashboardIcon}
            className="nav-icon"
            style={{ filter: dashboard ? "grayscale(0)" : "grayscale(1)" }}
            alt="dashboard"
          />
          <p className="nav-text">Dashboard</p>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className={control ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => {
            setControl(props.page === "control" ? true : !control);
            setControlOpen(!controlOpen);
            setReport(props.page === "report" ? true : false);
            setReportOpen(false);
            setSetting(props.page === "setting" ? true : false);
            setSettingOpen(false);
          }}
        >
          <img
            src={controlIcon}
            className="nav-icon"
            style={{ filter: control ? "grayscale(0)" : "grayscale(1)" }}
            alt="control"
          />
          <p className="nav-text">Control</p>
          <div
            className="chevron"
            style={{
              transform: controlOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></div>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className="nav-sub-container"
          style={{
            maxHeight: controlOpen ? 200 : 0,
          }}
        >
          <div onClick={() => navigate("/control")}>Building 1</div>
          <div onClick={() => navigate("/control")}>Building 2</div>
          <div onClick={() => navigate("/control")}>Building 3</div>
          <div onClick={() => navigate("/control")}>Building 4</div>
          <div onClick={() => navigate("/control")}>Building 5</div>
          <div onClick={() => navigate("/control")}>Building 6</div>
          <div onClick={() => navigate("/control")}>Building 7</div>
          <div onClick={() => navigate("/control")}>Building 8</div>
        </div>
        <div
          className={product ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => navigate("/product")}
        >
          <img
            src={productIcon}
            className="nav-icon"
            style={{ filter: product ? "grayscale(0)" : "grayscale(1)" }}
            alt="product"
          />
          <p className="nav-text">Product</p>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className={error ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => navigate("/error")}
        >
          <img
            src={errorIcon}
            className="nav-icon"
            style={{ filter: error ? "grayscale(0)" : "grayscale(1)" }}
            alt="error"
          />
          <p className="nav-text">Error</p>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className={report ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => {
            setReport(props.page === "report" ? true : !report);
            setReportOpen(!reportOpen);
            setControl(props.page === "control" ? true : false);
            setControlOpen(false);
          }}
        >
          <img
            src={reportIcon}
            className="nav-icon"
            style={{ filter: report ? "grayscale(0)" : "grayscale(1)" }}
            alt="report"
          />
          <p className="nav-text">Report</p>
          <div
            className="chevron"
            style={{ transform: reportOpen ? "rotate(90deg)" : "rotate(0deg)" }}
          ></div>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className="nav-sub-container"
          style={{
            maxHeight: reportOpen ? 200 : 0,
          }}
        >
          <div
            onClick={() => {
              navigate("/report", {
                state: {
                  report_type: "energy_report",
                  report_title: "Energy Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Energy Report
          </div>
          <div
            onClick={() => {
              navigate("/report", {
                state: {
                  report_type: "energy_summary_report",
                  report_title: "Energy Summary Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Energy Summary Report
          </div>
          <div
            onClick={() => {
              navigate("/report", {
                state: {
                  report_type: "iaq_report",
                  report_title: "IAQ Report",
                },
              });
              setReportOpen(false);
            }}
          >
            IAQ Report
          </div>
          <div
            onClick={() => {
              navigate("/report", {
                state: {
                  report_type: "iaq_summary_report",
                  report_title: "IAQ Summary Report",
                },
              });
              setReportOpen(false);
            }}
          >
            IAQ Summary Report
          </div>
          <div
            onClick={() => {
              navigate("/report", {
                state: {
                  report_type: "indoor_report",
                  report_title: "Indoor Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Indoor Report
          </div>
          <div
            onClick={() => {
              navigate("/report", {
                state: {
                  report_type: "maintenance_report",
                  report_title: "Maintenance Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Maintenance Report
          </div>
          <div
            onClick={() => {
              navigate("/report", {
                state: {
                  report_type: "schedule_report",
                  report_title: "Schedule Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Schedule Report
          </div>
          <div
            onClick={() => {
              navigate("/report", {
                state: {
                  report_type: "error_report",
                  report_title: "Error Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Error Report
          </div>
        </div>
        <div
          className={setting ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => {
            setControl(props.page === "control" ? true : false);
            setControlOpen(false);
            setReport(props.page === "report" ? true : false);
            setReportOpen(false);
            setSetting(props.page === "setting" ? true : !setting);
            setSettingOpen(!settingOpen);
          }}
        >
          <img
            src={settingIcon}
            className="nav-icon"
            style={{ filter: setting ? "grayscale(0)" : "grayscale(1)" }}
            alt="setting"
          />
          <p className="nav-text">Setting</p>
          <div
            className="chevron"
            style={{
              transform: settingOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></div>
        </div>
        <div
          className="nav-sub-container"
          style={{
            maxHeight: settingOpen ? 200 : 0,
          }}
        >
          <div onClick={() => navigate("/setting-building")}>
            Setting Building
          </div>
          <div onClick={() => navigate("/setting-floor")}>Setting Floor</div>
          <div onClick={() => navigate("/setting-room")}>Setting Room</div>
          <div onClick={() => navigate("/setting-ac")}>Setting AC</div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
