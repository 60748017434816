import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";

import ErrorIcon from "../assets/image/error/error.png";

const Error = (props) => {
  // const navigate = useNavigate();

  const [errorList, setErrorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = errorList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const arr = Array.apply(null, { length: 30 }).map(Number.call, Number);
    setErrorList(arr);
  }, []);

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4">
        <Row className="mx-0 ps-4">
          <Col className="pe-0">
            <div className="table-container p-4">
              <div className="table-container-title px-2">
                <img src={ErrorIcon} alt="error" />
                <h5 className="ms-3 mb-0">Error Code {"&"} Service Guidance</h5>
              </div>
              <div className="px-2">
                <Table hover className="mt-3 error-table">
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorList.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td></td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No Data</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={errorList.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Error;
