import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import loginLogo from "../assets/image/login_logo.png";
import User from "../assets/image/user.png";
import Password from "../assets/image/password.png";
import shadow from "../assets/image/shadow.png";
import moment from "moment";

const Login = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errorUser, setErrorUser] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const userHandle = (e) => {
    setUser(e.target.value);
    setErrorUser("");
  };

  const passwordHandle = (e) => {
    setPassword(e.target.value);
    setErrorPassword("");
  };

  const login = () => {
    if (user.length !== 0) {
      setErrorUser("");
    } else {
      setErrorUser("User can't be empty!");
    }

    if (password.length !== 0) {
      setErrorPassword("");
    } else {
      setErrorPassword("Password can't be empty!");
    }

    if (user === "admin" && password === "12345678") {
      localStorage.setItem(
        "exp",
        moment().add(1, "month").format("YYYY-MM-DD")
      );
      navigate("/");
    }
  };

  useEffect(() => {
    if (
      Date.parse(moment(localStorage.getItem("exp")).format("YYYY-MM-DD")) >
      Date.parse(moment().format("YYYY-MM-DD"))
    ) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="login-bg">
      <div className="login-container py-5">
        <img src={loginLogo} className="login-logo mb-5" alt="saijo-denki" />
        <Form className="login-form">
          <Form.Group className="mb-4">
            <div
              className={
                errorUser.length
                  ? "login-input-container error-input"
                  : "login-input-container"
              }
            >
              <div className="login-input-icon-container">
                <img src={User} className="login-icon" alt="user" />
              </div>
              <div className="login-input-box">
                <input
                  placeholder="Username"
                  className="login-input"
                  onChange={(e) => userHandle(e)}
                />
              </div>
            </div>
            {errorUser.trim().length ? (
              <p className="error-input">{errorUser}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <div
              className={
                errorPassword.length
                  ? "login-input-container error-input"
                  : "login-input-container"
              }
            >
              <div className="login-input-icon-container">
                <img src={Password} className="login-icon" alt="password" />
              </div>
              <div className="login-input-box">
                <input
                  placeholder="Password"
                  className="login-input"
                  type="password"
                  onChange={(e) => passwordHandle(e)}
                />
              </div>
            </div>
            {errorPassword.trim().length ? (
              <p className="error-input">{errorPassword}</p>
            ) : null}
          </Form.Group>
        </Form>
        <div className="login-other-container">
          <p
            className="create-account mx-2 px-1"
            onClick={() => navigate("/register")}
          >
            Create Account
          </p>
          <div className="middle-bar"></div>
          <p
            className="forget-password mx-2 px-1"
            onClick={() => navigate("/forget_password")}
          >
            Forget password ?
          </p>
        </div>
        <button type="button" className="login-btn" onClick={() => login()}>
          Login
        </button>
      </div>
      <img src={shadow} className="login-shadow" alt="shadow" />
    </div>
  );
};

export default Login;
