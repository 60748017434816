import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Register = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorSurname, setErrorSurname] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorUser, setErrorUser] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  const nameHandle = (e) => {
    setName(e.target.value);
    setErrorName("");
  };

  const surnameHandle = (e) => {
    setSurname(e.target.value);
    setErrorSurname("");
  };

  const emailHandle = (e) => {
    setEmail(e.target.value);
    setErrorEmail("");
  };

  const userHandle = (e) => {
    setUser(e.target.value);
    setErrorUser("");
  };

  const passwordHandle = (e) => {
    setPassword(e.target.value);
    setErrorPassword("");
  };

  const confirmPasswordHandle = (e) => {
    setConfirmPassword(e.target.value);
    setErrorConfirmPassword("");
  };

  const confirm = () => {
    var confirm = true;

    if (name.length) {
      setErrorName("");
    } else {
      setErrorName("Name is required!");
      confirm = false;
    }

    if (surname.length) {
      setErrorSurname("");
    } else {
      setErrorSurname("Surname is required!");
      confirm = false;
    }

    if (email.length) {
      if (validateEmail(email)) {
        setErrorEmail("");
      } else {
        setErrorEmail("Email is not valid!");
        confirm = false;
      }
    } else {
      setErrorEmail("Email is required!");
      confirm = false;
    }

    if (user.length) {
      setErrorUser("");
    } else {
      setErrorUser("Username is required!");
      confirm = false;
    }

    if (password.length) {
      if (password.length < 8 && password.length > 20) {
        setErrorPassword("Password must between 8 and 20 character!");
        confirm = false;
      } else {
        setErrorPassword("");
      }
    } else {
      setErrorPassword("Password is required!");
    }

    if (confirmPassword.length) {
      if (confirmPassword === password) {
        setErrorConfirmPassword("");
      } else {
        setErrorConfirmPassword("Password don't match!");
        confirm = false;
      }
    } else {
      setErrorConfirmPassword("Confirm password is required!");
      confirm = false;
    }

    if (confirm) {
      localStorage.setItem(
        "exp",
        moment().add(1, "month").format("YYYY-MM-DD")
      );
      navigate("/");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="login-bg">
      <div className="form-container py-5">
        <p className="form-title">Register</p>
        <Row className="login-form">
          <Col xs={6}>
            <Form.Group className="mb-4">
              <Form.Label className={"input-label"}>Name</Form.Label>
              <div className={"login-input-container"}>
                <div className="login-input-box">
                  <input
                    placeholder="Name"
                    className="login-input"
                    onChange={(e) => nameHandle(e)}
                  />
                </div>
              </div>
              {errorName.trim().length ? (
                <p className="error-input">{errorName}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-4">
              <Form.Label className={"input-label"}>Surname</Form.Label>
              <div className={"login-input-container"}>
                <div className="login-input-box">
                  <input
                    placeholder="Surname"
                    className="login-input"
                    onChange={(e) => surnameHandle(e)}
                  />
                </div>
              </div>
              {errorSurname.trim().length ? (
                <p className="error-input">{errorSurname}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-4">
              <Form.Label className={"input-label"}>Email</Form.Label>
              <div className={"login-input-container"}>
                <div className="login-input-box">
                  <input
                    placeholder="Email"
                    className="login-input"
                    onChange={(e) => emailHandle(e)}
                  />
                </div>
              </div>
              {errorEmail.trim().length ? (
                <p className="error-input">{errorEmail}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-4">
              <Form.Label className={"input-label"}>Username</Form.Label>
              <div className={"login-input-container"}>
                <div className="login-input-box">
                  <input
                    placeholder="Username"
                    className="login-input"
                    onChange={(e) => userHandle(e)}
                  />
                </div>
              </div>
              {errorUser.trim().length ? (
                <p className="error-input">{errorUser}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-4">
              <Form.Label className={"input-label"}>Password</Form.Label>
              <div className={"login-input-container"}>
                <div className="login-input-box">
                  <input
                    placeholder="Password"
                    className="login-input"
                    type="password"
                    onChange={(e) => passwordHandle(e)}
                  />
                </div>
              </div>
              {errorPassword.trim().length ? (
                <p className="error-input">{errorPassword}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-4">
              <Form.Label className={"input-label"}>
                Confirm Password
              </Form.Label>
              <div className={"login-input-container"}>
                <div className="login-input-box">
                  <input
                    placeholder="Confirm Password"
                    className="login-input"
                    type="password"
                    onChange={(e) => confirmPasswordHandle(e)}
                  />
                </div>
              </div>
              {errorConfirmPassword.trim().length ? (
                <p className="error-input">{errorConfirmPassword}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col>
            <button
              type="button"
              className="login-btn w-50 mx-auto"
              onClick={() => confirm()}
            >
              Confirm
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Register;
