import React, { useState, useContext, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { io } from "socket.io-client";

import logoutIcon from "../assets/image/header/logout.png";

import { ProfileContext } from "./profile_context";

const Header = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      !localStorage.getItem("exp") ||
      Date.parse(moment(localStorage.getItem("exp")).format("YYYY-MM-DD")) <
        Date.parse(moment().format("YYYY-MM-DD"))
    ) {
      navigate("/login");
    } else {
      const socket = io("wss://broker.mqttdashboard.com:8000");

      socket.connect();

      if (socket.connected) {
        console.log("connected");
      }
    }
  }, [navigate]);

  const logout = () => {
    localStorage.removeItem("exp");

    navigate("/login");
  };

  const { name, surname, building, image } = useContext(ProfileContext);

  return (
    <div className="header">
      <Row className="header-row mx-0">
        <Col xs={9}>
          <h5 className="header-title px-4 pb-2">
            {building.length ? building : "โรงพยาบาล xxxxxxxxxxxxxxxxxxxxxx"}
          </h5>
        </Col>
        <Col>
          <div className="user-container mb-1">
            <p className="header-name mb-0">
              {name ? name : "Name"} {surname ? surname : "Surname"}
            </p>
            <img
              src={image}
              className="user-image"
              onClick={() => setOpen(!open)}
              alt="user"
              style={{ width: "39px", height: "39px" }}
            />
            <div
              className="user-dropdown"
              style={{
                visibility: open ? "visible" : "hidden",
                opacity: open ? 1 : 0,
              }}
            >
              <div
                className="user-profile-btn"
                onClick={() => navigate("/profile")}
              >
                User Profile
              </div>
              <div
                className="user-profile-btn"
                onClick={() => navigate("/change_password")}
              >
                Change Password
              </div>
              <div
                className="user-profile-btn"
                onClick={() => {
                  logout();
                }}
              >
                Logout
                <img src={logoutIcon} className="logout-icon" alt="logout" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
