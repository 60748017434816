import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Table, Modal, Form, Button } from "react-bootstrap";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";

import Floor from "../assets/image/dashboard/floor-demo.png";
import Upload from "../assets/image/setting/upload.png";

const SettingFloor = (props) => {
  const [floorList, setFloorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [show, setShow] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [validated, setValidated] = useState(false);
  const [floorImage, setFloorImage] = useState(null);
  const [validateImage, setValidateImage] = useState(true);
  const [buildingSearch, setBuildingSearch] = useState("");
  const [floorSearch, setFloorSearch] = useState("");
  const [floorListSearch, setFloorListSearch] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = floorListSearch.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);

  useEffect(() => {
    const floor_list = [
      {
        building_name: "อาคาร 1",
        floor_name: "ชั้น 1",
        floor_image: Floor,
      },
      {
        building_name: "อาคาร 2",
        floor_name: "ชั้น 2",
        floor_image: Floor,
      },
      {
        building_name: "อาคาร 3",
        floor_name: "ชั้น 3",
        floor_image: Floor,
      },
      {
        building_name: "อาคาร 1",
        floor_name: "ชั้น 4",
        floor_image: Floor,
      },
      {
        building_name: "อาคาร 2",
        floor_name: "ชั้น 5",
        floor_image: Floor,
      },
      {
        building_name: "อาคาร 3",
        floor_name: "ชั้น 6",
        floor_image: Floor,
      },
    ];

    setFloorList(floor_list);
    setFloorListSearch(floor_list);
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!floorImage) {
      setValidateImage(false);
    }

    setValidated(true);
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      setValidateImage(true);
      setFloorImage(e.target.files[0]);
    }
  };

  const search = () => {
    var result = floorList.filter((item) => {
      if (
        item.building_name.toLowerCase().indexOf(buildingSearch) >= 0 &&
        item.floor_name.toLowerCase().indexOf(floorSearch) >= 0
      ) {
        return true;
      } else {
        return false;
      }
    });

    setFloorListSearch(result);
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4">
        <Row className="mx-0 ps-4">
          <Col className="pe-0">
            <div className="table-container p-4">
              <div
                className="table-container-title px-2"
                style={{ justifyContent: "space-between" }}
              >
                <h5 className="mb-1">Floor Management</h5>
                <button
                  className="add-modal-btn"
                  onClick={() => {
                    setBuilding("");
                    setFloor("");
                    setFloorImage(null);
                    setAddForm(true);
                  }}
                >
                  Add Floor
                </button>
              </div>
              <Row>
                <Col xs={3} xxl={2} className="pe-0">
                  <Form.Group className="mt-4 mb-2 px-2">
                    <Form.Label className="input-label">
                      Building Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Building"
                      required
                      onChange={(e) => setBuildingSearch(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3} xxl={2} className="pe-0">
                  <Form.Group className="mt-4 mb-2 px-2">
                    <Form.Label className="input-label">Floor Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Floor"
                      required
                      onChange={(e) => setFloorSearch(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mt-4 mb-2 px-2">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="search-btn" onClick={() => search()}>
                    Search
                  </button>
                </Col>
              </Row>
              <div className="px-2">
                <Table hover className="mt-3 setting-table align-middle">
                  <thead>
                    <tr>
                      <th className="px-4">No.</th>
                      <th className="text-center px-4">Image</th>
                      <th className="text-center px-4">Building Name</th>
                      <th className="text-center px-4">Floor Name</th>
                      <th className="text-center px-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {floorListSearch.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4">
                            {currentPage === 1
                              ? index + 1
                              : index + 1 + (currentPage - 1) * PerPage}
                            .
                          </td>
                          <td className="px-4 text-center" width={"10%"}>
                            <img
                              src={item.floor_image}
                              alt="floor"
                              className="table-image py-2"
                              onClick={() => {
                                setModalImage(item.floor_image);
                                setShow(true);
                              }}
                            />
                          </td>
                          <td className="px-4 text-center" width={"20%"}>
                            {item.building_name}
                          </td>
                          <td className="px-4 text-center" width={"20%"}>
                            {item.floor_name}
                          </td>
                          <td className="px-4 text-center">
                            <span
                              className="table-edit"
                              onClick={() => {
                                setBuilding(item.building_name);
                                setFloor(item.floor_name);
                                setModalImage(item.floor_image);
                                setEditForm(true);
                              }}
                            >
                              Edit
                            </span>{" "}
                            /{" "}
                            <span
                              className="table-delete"
                              onClick={() => setDeleteModal(true)}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={floorListSearch.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal size="lg" show={show} onHide={() => setShow(false)} centered>
        <div className="position-relative">
          <img src={modalImage} alt="modal" className="w-100 h-auto" />
          <button
            type="button"
            className="btn-close modal-close-btn"
            aria-label="Close"
            onClick={() => setShow(false)}
          />
        </div>
      </Modal>
      <Modal show={editForm} onHide={() => setEditForm(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Edit setFloor</h4>
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => handleSubmit(e, "edit")}
          >
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Building Name</Form.Label>
              <Form.Select
                onChange={(e) => setBuilding(e.target.value)}
                required
                value={building}
              >
                <option value="">Select Building</option>
                <option value="อาคาร 1">อาคาร 1</option>
                <option value="อาคาร 2">อาคาร 2</option>
                <option value="อาคาร 3">อาคาร 3</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please provide a valid floor name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Floor Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Building"
                defaultValue={floor}
                required
                onChange={(e) => setFloor(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid floor name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Floor Image</Form.Label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  ref={imageUploader}
                  style={{
                    display: "none",
                  }}
                />
                <div
                  className="form-image-container mb-2"
                  onClick={() => imageUploader.current.click()}
                >
                  <img
                    ref={uploadedImage}
                    src={modalImage}
                    className="form-image-input"
                    alt="profile"
                  />
                </div>
              </div>
            </Form.Group>
            <div className="modal-btn-container mb-2">
              <Button
                type="reset"
                onClick={() => {
                  setEditForm(false);
                  setValidateImage(true);
                  setValidated(false);
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={addForm} onHide={() => setAddForm(true)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Add Building</h4>
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => handleSubmit(e, "add")}
          >
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Building Name</Form.Label>
              <Form.Select
                onChange={(e) => setBuilding(e.target.value)}
                required
              >
                <option value="">Select Building</option>
                <option value="อาคาร 1">อาคาร 1</option>
                <option value="อาคาร 2">อาคาร 2</option>
                <option value="อาคาร 3">อาคาร 3</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select building.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Floor Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Floor"
                onChange={(e) => setFloor(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid floor name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Building Image</Form.Label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  ref={imageUploader}
                  style={{
                    display: "none",
                  }}
                  required
                />
                <div
                  className={
                    validateImage
                      ? "form-image-container"
                      : "form-image-container error-image-upload"
                  }
                  onClick={() => imageUploader.current.click()}
                >
                  <img
                    ref={uploadedImage}
                    src={Upload}
                    className="form-image-input"
                    alt="profile"
                  />
                </div>
                <p
                  className="form-error mb-0"
                  style={{ display: validateImage ? "none" : "block" }}
                >
                  Please provide a building image.
                </p>
              </div>
            </Form.Group>
            <div className="modal-btn-container mb-2">
              <Button
                type="reset"
                onClick={() => {
                  setAddForm(false);
                  setValidated(false);
                  setValidateImage(true);
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} centered>
        <Modal.Body>
          <h4 className="modal-title mb-4">Do you want delete this floor ?</h4>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SettingFloor;
