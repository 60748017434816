import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./routes/dashboard";
import Control from "./routes/control";
import Product from "./routes/product";
import Error from "./routes/error";
import Report from "./routes/report";
import Login from "./routes/login";
import Register from "./routes/register";
import ForgetPassword from "./routes/forget_password";
import Profile from "./routes/profile";
import ChangePassword from "./routes/change_password";
import SettingBuilding from "./routes/setting_building";
import SettingFloor from "./routes/setting_floor";
import SettingRoom from "./routes/setting-room";
import SettingPosition from "./routes/setting-position";
import SettingAC from "./routes/setting-ac";

import { MqttContext } from "./components/mqtt_context";
import { ProfileContext } from "./components/profile_context";

import moment from "moment";
import ProfileImage from "./assets/image/user_128.png";

const App = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [building, setBuilding] = useState("");
  const [image, setImage] = useState(ProfileImage);

  if (
    Date.parse(moment(localStorage.getItem("exp")).format("YYYY-MM-DD")) <
    Date.parse(moment().format("YYYY-MM-DD"))
  ) {
    console.log("auth");
  }

  const update_profile = (name, surname, building, image) => {
    setName(name);
    setSurname(surname);
    setBuilding(building);
    setImage(
      "https://api.saijo-denki.com/img/core/upload/profile_img/19062.png"
    );
  };

  return (
    <ProfileContext.Provider
      value={{ update_profile, name, surname, building, image }}
    >
      <MqttContext.Provider value={null}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard page={"dashboard"} />} />
            <Route path="/control" element={<Control page={"control"} />} />
            <Route path="/product" element={<Product page={"product"} />} />
            <Route path="/error" element={<Error page={"error"} />} />
            <Route path="/report" element={<Report page={"report"} />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/change_password" element={<ChangePassword />} />
            <Route path="/register" element={<Register page={"register"} />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/forget_password"
              element={<ForgetPassword page={"forget_password"} />}
            />
            <Route
              path="/setting-position"
              element={<SettingPosition page={"setting"} />}
            />
            <Route
              path="/setting-building"
              element={<SettingBuilding page={"setting"} />}
            />
            <Route
              path="/setting-floor"
              element={<SettingFloor page={"setting"} />}
            />
            <Route
              path="/setting-room"
              element={<SettingRoom page={"setting"} />}
            />
            <Route
              path="/setting-ac"
              element={<SettingAC page={"setting"} />}
            />
          </Routes>
        </BrowserRouter>
      </MqttContext.Provider>
    </ProfileContext.Provider>
  );
};

export default App;
