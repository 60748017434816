import React, { useState, useEffect } from "react";
import { Row, Col, Table, Modal, Form, Button } from "react-bootstrap";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";

const SettingAC = (props) => {
  const [acList, setAcList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [addForm, setAddForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [acName, setAcName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [room, setRoom] = useState("");
  const [validated, setValidated] = useState(false);
  const [serialNumberSearch, setSerialNumberSearch] = useState("");
  const [acNameSearch, setAcNameSearch] = useState("");
  const [roomSearch, setRoomSearch] = useState("");
  const [acListSearch, setAcListSearch] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = acListSearch.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const ac_list = [
      {
        ac_name: "Name 1",
        serial_number: "SN 123456789",
        room_name: "ห้อง 1",
      },
      {
        ac_name: "Name 2",
        serial_number: "SN 123456789",
        room_name: "ห้อง 2",
      },
      {
        ac_name: "Name 3",
        serial_number: "SN 123456789",
        room_name: "ห้อง 1",
      },
      {
        ac_name: "Name 4",
        serial_number: "SN 123456789",
        room_name: "ห้อง 2",
      },
      {
        ac_name: "Name 5",
        serial_number: "SN 123456789",
        room_name: "ห้อง 3",
      },
      {
        ac_name: "Name 6",
        serial_number: "SN 123456789",
        room_name: "ห้อง 3",
      },
    ];

    setAcList(ac_list);
    setAcListSearch(ac_list);
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const search = () => {
    var result = acList.filter((item) => {
      if (
        item.ac_name.toLowerCase().indexOf(acNameSearch) >= 0 &&
        item.serial_number.toLowerCase().indexOf(serialNumberSearch) >= 0 &&
        item.room_name.toLowerCase().indexOf(roomSearch) >= 0
      ) {
        return true;
      } else {
        return false;
      }
    });

    setAcListSearch(result);
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4">
        <Row className="mx-0 ps-4">
          <Col className="pe-0">
            <div className="table-container p-4">
              <div
                className="table-container-title px-2"
                style={{ justifyContent: "space-between" }}
              >
                <h5 className="mb-1">AC Management</h5>
                <button
                  className="add-modal-btn"
                  onClick={() => {
                    setAcName("");
                    setSerialNumber("");
                    setAddForm(true);
                  }}
                >
                  Add AC
                </button>
              </div>
              <Row>
                <Col xs={3} xxl={2} className="pe-0">
                  <Form.Group className="mt-4 mb-2 px-2">
                    <Form.Label className="input-label">AC Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="AC Name"
                      required
                      onChange={(e) => setAcNameSearch(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3} xxl={2} className="pe-0">
                  <Form.Group className="mt-4 mb-2 px-2">
                    <Form.Label className="input-label">
                      Serial Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Serial Number"
                      required
                      onChange={(e) => setSerialNumberSearch(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3} xxl={2} className="pe-0">
                  <Form.Group className="mt-4 mb-2 px-2">
                    <Form.Label className="input-label">Room Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Room Name"
                      required
                      onChange={(e) => setRoomSearch(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mt-4 mb-2 px-2">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="search-btn" onClick={() => search()}>
                    Search
                  </button>
                </Col>
              </Row>
              <div className="px-2">
                <Table hover className="mt-3 setting-table align-middle">
                  <thead>
                    <tr>
                      <th className="px-4">No.</th>
                      <th className="text-center px-4">AC Name</th>
                      <th className="text-center px-4">Serial Number</th>
                      <th className="text-center px-4">Room Name</th>
                      <th className="text-center px-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {acListSearch.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4">
                            {currentPage === 1
                              ? index + 1
                              : index + 1 + (currentPage - 1) * PerPage}
                            .
                          </td>
                          <td className="px-4 text-center">{item.ac_name}</td>
                          <td className="px-4 text-center">
                            {item.serial_number}
                          </td>
                          <td className="px-4 text-center">{item.room_name}</td>
                          <td className="px-4 text-center">
                            <span
                              className="table-edit"
                              onClick={() => {
                                setAcName(item.ac_name);
                                setSerialNumber(item.serial_number);
                                setRoom(item.room_name);
                                setEditForm(true);
                              }}
                            >
                              Edit
                            </span>{" "}
                            /{" "}
                            <span
                              className="table-delete"
                              onClick={() => setDeleteModal(true)}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={acListSearch.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal show={editForm} onHide={() => setEditForm(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Edit setFloor</h4>
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => handleSubmit(e, "edit")}
          >
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">AC Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="AC Name"
                defaultValue={acName}
                required
                onChange={(e) => setAcName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid ac name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Serial Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Serial Number"
                defaultValue={serialNumber}
                required
                onChange={(e) => setSerialNumber(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid serial number.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Room Name</Form.Label>
              <Form.Select
                onChange={(e) => setRoom(e.target.value)}
                required
                value={room}
              >
                <option value="">Select Room</option>
                <option value="ห้อง 1">ห้อง 1</option>
                <option value="ห้อง 2">ห้อง 2</option>
                <option value="ห้อง 3">ห้อง 3</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please provide a valid room name.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="modal-btn-container mb-2">
              <Button
                type="reset"
                onClick={() => {
                  setEditForm(false);
                  setValidated(false);
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={addForm} onHide={() => setAddForm(true)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Add AC</h4>
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => handleSubmit(e, "add")}
          >
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">AC Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="AC Name"
                onChange={(e) => setAcName(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid ac name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Serial Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Serial Number"
                onChange={(e) => setSerialNumber(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid serial number.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Room Name</Form.Label>
              <Form.Select onChange={(e) => setRoom(e.target.value)} required>
                <option value="">Select Building</option>
                <option value="ห้อง 1">ห้อง 1</option>
                <option value="ห้อง 2">ห้อง 2</option>
                <option value="ห้อง 3">ห้อง 3</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select room.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="modal-btn-container mb-2">
              <Button
                type="reset"
                onClick={() => {
                  setAddForm(false);
                  setValidated(false);
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} centered>
        <Modal.Body>
          <h4 className="modal-title mb-4">Do you want delete this ac ?</h4>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SettingAC;
