import React, { useState, useEffect } from "react";
import { Row, Col, Table, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import ExportExcel from "../components/export_excel";

import BuildingIcon from "../assets/image/control/building-icon.png";
import FloorIcon from "../assets/image/control/floor-icon.png";
import RoomIcon from "../assets/image/control/room-icon.png";

const Report = (props) => {
  const { state } = useLocation();

  const [reportList, setReportList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [open, setOpen] = useState(false);
  const [building, setBuilding] = useState("Building 1");
  const [floor, setFloor] = useState("Floor 1");
  const [room, setRoom] = useState("Room 1");

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = reportList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const arr = Array.apply(null, { length: 30 }).map(Number.call, Number);
    setReportList(arr);
  }, []);

  const filterACRoom = () => {
    console.log(building, floor, room);
    setOpen(false);
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4">
        <Row className="mx-0 ps-4">
          <Col className="pe-0">
            <div className="table-container p-4">
              <div className="table-container-title px-2">
                <Row>
                  <Col xs={"auto"} className="position-relative">
                    <h5 className="mb-1">
                      {state?.report_title
                        ? state.report_title
                        : "Energy Report"}
                    </h5>
                    <div
                      className="control-select-dropdown"
                      style={{ top: "5px" }}
                      onClick={() => setOpen(!open)}
                    ></div>
                    <div
                      className="control-select-container p-2"
                      style={{
                        visibility: open ? "visible" : "hidden",
                        opacity: open ? 1 : 0,
                      }}
                    >
                      <div className="control-select">
                        <img
                          src={BuildingIcon}
                          alt="building"
                          className="pe-2"
                        />
                        <p className="mb-0 pe-2">Building</p>
                        <Form.Select
                          size="sm"
                          onChange={(e) => setBuilding(e.target.value)}
                        >
                          <option value="Building 1">Building 1</option>
                          <option value="Building 2">Building 2</option>
                          <option value="Building 3">Building 3</option>
                        </Form.Select>
                      </div>
                      <div className="control-select">
                        <img src={FloorIcon} alt="floor" className="pe-2" />
                        <p className="mb-0 pe-2">Floor</p>
                        <Form.Select
                          size="sm"
                          onChange={(e) => setFloor(e.target.value)}
                        >
                          <option value="Floor 1">Floor 1</option>
                          <option value="Floor 2">Floor 2</option>
                          <option value="Floor 3">Floor 3</option>
                        </Form.Select>
                      </div>
                      <div className="control-select">
                        <img src={RoomIcon} alt="floor" className="pe-2" />
                        <p className="mb-0 pe-2">Room</p>
                        <Form.Select
                          size="sm"
                          onChange={(e) => setRoom(e.target.value)}
                        >
                          <option value="Room 1">Room 1</option>
                          <option value="Room 2">Room 2</option>
                          <option value="Room 3">Room 3</option>
                        </Form.Select>
                      </div>
                      <button
                        className="control-search-btn my-2 me-2"
                        onClick={() => filterACRoom()}
                      >
                        Confirm
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
              <p className="report-time px-2 mb-4">
                {moment().format("DD/MM/YYYY [TIME] HH:MM")}
              </p>
              <ExportExcel
                csvData={[
                  {
                    Demo: 123,
                  },
                  {
                    Demo: 456,
                  },
                ]}
                fileName={
                  state?.report_title
                    ? state.report_title +
                      "_" +
                      moment().format("DD_MM_YYYY_HH_MM_SS") +
                      "_.csv"
                    : "Energy Report_" +
                      moment().format("DD_MM_YYYY_HH_MM_SS") +
                      "_.csv"
                }
              />
              <div className="px-2">
                <Table hover className="mt-3 error-table">
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportList.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td></td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No Data</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={reportList.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Report;
